<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="7">
          <user-list></user-list>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="5">
          <v-card hover>
            <v-card-title> Messages </v-card-title>
            <v-card-text>
              <message-list></message-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  components: {
    UserList: () => import('@/components/Admin/UserList'),
    MessageList: () => import('@/components/Admin/MessageList')
  },
  metaInfo: {
    title: 'Admin'
  },
  mounted() {}
}
</script>

<style></style>
